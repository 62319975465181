<template>
    <div>
        <Loading v-if="isLoading"/>

        <div class="p-grid" v-show="!isLoading">

            <!-- Toolbar -->    
            <TitlePage 
                title="UBICACIONES" 
                :reset="false" 
            />

            <div class="row">
                <div class="col-12 ml-3">
                    <p class="text-blue-600 font-semibold text-lg">El precio de la silla/banqueta extra está fijado en <span class="text-orange-500 text-xl">{{ this.design.Currency(5000)}}</span></p>
                </div>
            </div>

            <!-- Table Result -->
            <div v-show="dataSet.length">
                <div class="col-12">
                    <DataTable 
                        :value="dataSet" 
                        :rows="20"
                        :rowClass="rowClass"
                        :paginator="true" 
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        responsiveLayout="scroll" 
                        :rowHover="true"
                        v-model:filters="filters" 
                        filterDisplay="row"
                        :globalFilterFields="['id','name','row','col','precioA','precioB','precioC']"
                    >
                        <template #header>
                            <div class="flex justify-content-start">
                                
                                <span class="p-input-icon-left ">
                                    <i class="fas fa-search" />
                                    <InputText v-model="filters['global'].value" class="ml-2" placeholder="Filtrar Datos" />
                                </span>
                            </div>
                        </template>

                        <template #empty>{{ strings.es.dataTable.empty }}</template>
                        <template #loading>{{ strings.es.dataTable.loading }}</template>

                        <Column 
                            field="id" 
                            header="ID" 
                            :sortable="true" 
                            :style="{width:'50px'}"/>

                        <Column 
                            field="col" 
                            header="Mesa" 
                            :sortable="true">
                            <template #body="row">
                               Mesa {{row.data.col}}
                            </template>
                        </Column>

                        <Column 
                            field="row"
                            :sortable="true" 
                            header="Fila">
                            <template #body="row">
                               Fila {{row.data.row}}
                            </template>
                        </Column>

                        <Column 
                            field="group.name" 
                            header="Grupo" 
                            :sortable="true">
                            <template #body="row">
                               {{row.data.group.name}}
                            </template>
                        </Column>

                        <Column 
                            field="precioA" 
                            header="P. Enero" 
                            :sortable="true">
                            <template #body="row">
                               {{this.design.Currency(row.data.precioA)}}
                            </template>
                        </Column>

                        <Column 
                            field="precioB" 
                            header="P. Febrero" 
                            :sortable="true">
                            <template #body="row">
                               {{this.design.Currency(row.data.precioB)}}
                            </template>
                        </Column>

                        <Column 
                            field="precioC" 
                            header="P. Feriado" 
                            :sortable="true">
                            <template #body="row">
                               {{this.design.Currency(row.data.precioC)}}
                            </template>
                        </Column>

                        <Column 
                            field="user_id" 
                            header="Usuarios"
                            :sortable="true">
                            <template #body="row">
                               {{row.data.user_id && row.data.user_id.length > 0 ? 'LIMITADO' : 'TODOS'}}
                            </template>
                        </Column>

                        <Column 
                            header="Modificado"
                            :sortable="true" >
                            <template #body="row">{{ (row.data.updated_at != null) ? $moment.utc(row.data.updated_at, 'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm") : '-' }}</template>
                        </Column>

                        <Column :style="{width:'275px', 'text-align':'center' }">
                            <template #body="slotProps">
                                <span class="p-buttonset">
                                    <Btn
                                        label='EDITAR'
                                        iconPos='left'
                                        @click="editPlace(slotProps.data)"
                                        icon='fas fa-edit'
                                        classBtn='p-button-info p-button-text p-button-sm'
                                    />
                                </span>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>

            <NoResult v-show="!dataSet.length && !isLoading"/>


            <Edit 
                v-if="showDialogEdit" 
                :show="showDialogEdit" 
                :data="dialogData" 
                @handleClose="handleClose"
            />
        </div>

        <br><br>
    </div>
</template>

<script>
    import apiService from '../../services/apiService'
    import { FilterMatchMode } from 'primevue/api'
    import Edit from './Dialogs/Edit'

    export default {
        components: {
            Edit,
        },
        data(){
            return{
                dataSet: [],
                isLoading: false,
                showDialogEdit: false,
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'row': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'col': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'precioA': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'precioB': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'precioC': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                },
            }
        },
        async mounted(){
            this.index()
        },
        methods: {
            async index(){
                this.isLoading = true
                apiService.Place.all()
                .then((result) => {
                    this.dataSet = result
                    this.isLoading = false
                })
            },

            editPlace(place){
                this.dialogData = place
                this.showDialogEdit = true
            },

            handleClose(){
                this.dialogData = null
                this.showDialogEdit = false
            },

            // Styles
            rowClass(data) {
                return !data.state ? 'bg-pink-50': null;
            }
        }
    }
</script>
