<template>
    <div>
        <Dialog 
            v-model:visible="showDialog" 
            :modal="true" 
            :style="{ width: '75vw' }"
            :closeOnEscape="false"
            :closable="false"
            class="p-dialog-maximized">
            <template #header>
                <div class="grid">
                    <div class="col-xs-12">
                        <h2 class="mt-0 mb-0 pb-0">FILA {{place.row}} - {{place.name}}</h2>
                        <h3 class="mt-0 pt-0 text-500">{{place.group.name}} ({{place.group.description}})</h3>
                    </div>
                </div>
            </template>

            <div class="card mt-1">
                <form @submit.prevent="updatePlace">
                    <div class="formgrid grid">
                        <div class="field col-4 md:col-4 lg:col-4 mt-3">
                            <label for="precioA">P. Enero</label>
                            <InputNumber
                                id="precioA" 
                                ref="precioA" 
                                v-model="place.precioA"
                                class="inputfield w-full" 
                                placeholder="Valor"
                                :maxFractionDigits="2"
                                mode="currency" 
                                currency="ARS" locale="es-AR"
                            />
                        </div>
                        <div class="field col-4 md:col-4 lg:col-4 mt-3">
                            <label for="precioB">P. Febrero</label>
                            <InputNumber
                                id="precioB" 
                                ref="precioB" 
                                v-model="place.precioB"
                                class="inputfield w-full" 
                                placeholder="Valor"
                                :maxFractionDigits="2"
                                mode="currency" 
                                currency="ARS" locale="es-AR"
                            />
                        </div>
                        <div class="field col-4 md:col-4 lg:col-4 mt-3">
                            <label for="precioC">P. Feriado</label>
                            <InputNumber
                                id="precioC" 
                                ref="precioC" 
                                v-model="place.precioC"
                                class="inputfield w-full" 
                                placeholder="Valor"
                                :maxFractionDigits="2"
                                mode="currency" 
                                currency="ARS" locale="es-AR"
                            />
                        </div>
                        <div class="field col-6 md:col-6 lg:col-6 mt-3">
                            <label for="user_id">Usuarios</label>
                            <MultiSelect 
                                id="user_id" 
                                ref="user_id" 
                                v-model="place.user_id" 
                                :options="users" 
                                optionLabel="name" 
                                optionValue="id"
                                placeholder="Seleccionar Usuarios *" 
                                class="w-full"
                                display="chip"
                            />
                            <small class="text-500">Dejar vacio para permitir cualquier usuario</small>
                        </div>
                    </div>
                </form>
            </div>
            
            <template #footer>
                <div class="grid">
                    <div class="col-6 mt-4 text-left">
                        <Button 
                            class="p-button-lg p-button-outlined p-button-danger" 
                            label="CERRAR" 
                            @click="handleClose"
                            icon="fas fa-times" />
                    </div>
                    <div class="col-6 mt-4 text-right">
                        <Button 
                            class="p-button-lg p-button-outlined" 
                            label="ACTUALIZAR" 
                            @click="updatePlace"
                            icon="fas fa-save" />
                    </div>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>

    import apiService from '../../../services/apiService'

    export default {
        name: 'EditPlace',
        props:{
            show: {
                type: Boolean,
                default: false,
            },
            data: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                place: null,
                users: [],
                showDialog: false,
            }
        },
        created(){
            this.place = this.data;
            this.place.precioA = parseFloat(this.data.precioA)
            this.place.precioB = parseFloat(this.data.precioB)
            this.place.precioC = parseFloat(this.data.precioC)
            this.getUsers()
        },
        mounted(){
            this.showDialog = this.show
        },
        methods: {
            updatePlace(){
                this.$confirm.require({
                message: 'Continuar y actualizar la ubicacion seleccionada?.',
                header: 'Confirmación',
                icon: 'fas fa-key',
                accept: () => {
                    this.isLoading = true
                    apiService.Place.update(this.place.id, this.place)
                    .then((result) => {
                        if(result.status == 'success'){
                            this.FormSuccess(result.message)
                            this.isLoading = false
                            this.handleClose()
                        }else{
                            this.FormErrors(result)
                            this.isLoading = false
                        }
                    }).catch((e) => {
                        this.FormRequestFail(e);
                        this.isLoading = false
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
            },
            handleClose(){
                this.showDialog = false
                this.$emit('handleClose', this.place);
            },
            getUsers(){
                apiService.Catalog.user()
                .then((result) => {
                    this.users = result;
                })
            }
        }
    }
</script>

